<template>
  <div>
    <v-card elevation="10" :loading="loading">
      <v-app-bar color="blue-grey darken-1" dense dark>
        <v-toolbar-title>รายละเอียดการจองคิว</v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- Save update -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click.stop="update"
              :disabled="queueId.length == 0"
              v-on="on"
              v-bind="attrs"
              small
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>บันทึกแก้ไข</span>
        </v-tooltip>

        <!-- mark as completed -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-on="on"
              :disabled="queueId.length == 0"
              @click.prevent.stop="setStatus(item.id)"
              v-bind="attrs"
            >
              <v-icon>mdi-notebook-check</v-icon>
            </v-btn>
          </template>
          <span>บันทึกว่าดำเนินการเสร็จสิ้นแล้ว</span>
        </v-tooltip>

        <!-- delete -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click.stop="deleteQueue"
              :disabled="queueId.length == 0"
              v-on="on"
              v-bind="attrs"
              small
            >
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
          <span>ลบคิว</span>
        </v-tooltip>
      </v-app-bar>
      <v-container>
        <v-form v-model="valid">
          <v-text-field
            label="Queue Id"
            v-model="queue.id"
            readonly
          ></v-text-field>
          <v-menu
            v-model="datepicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="วันที่จองคิว"
                v-model="queue.queueDate"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="queue.queueDate"
              @input="datepicker = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            :items="['09.00-12.00', '13.00-16.00', '16.00-18.00']"
            v-model="queue.queueTime"
            label="ช่วงเวลา"
          ></v-select>
          <v-text-field
            label="บันทึกอื่นๆ"
            v-model="queue.remark"
          ></v-text-field>
          <v-text-field
            label="จองโดย"
            v-model="queue.queueBy"
            readonly
            append-icon="mdi-information"
            @click:append="showAgentInfo"
          ></v-text-field>
          <v-text-field
            label="วันที่ทำรายการ"
            v-model="queue.queueAt"
            readonly
          ></v-text-field>
          <v-checkbox v-model="queue.vip" label="คิว VIP"></v-checkbox>
        </v-form>
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              รายชื่อคนต่างด้าว
              <!-- <template v-slot:default="{ open }"> </template> -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list two-line dense>
                <!-- <v-subheader>รายชื่อคนต่างด้าว</v-subheader> -->
                <template v-for="(item, index) in queue.workers">
                  <v-list-item
                    :key="item.rid"
                    link
                    @click.stop="showWorkerDetail(index)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.refId }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-row align="center" justify="end">
                      <v-btn @click.prevent.stop="showWorkerDetail(index)" icon>
                        <v-icon> mdi-information </v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
    <!-- snackbar -->
    <v-snackbar v-model="snackbar" top>
      {{ status }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- modal agent info -->
    <modal-agent v-model="agent" :id="queue.queueById" @close="agent = false" />
    <!-- modal worker info -->
    <modal-worker
      v-model="worker"
      :worker="workerSelected"
      @close="worker = false"
    />
    <confirm-dialog ref="confirm" />
  </div>
</template>

<script>
import { view, updateQueue, deleteQ, setcompleted } from "../../api/queue";
import modalAgent from "./modalAgent";
import modalWorker from "./modalWorker";
export default {
  props: ["queueId"],
  components: {
    modalAgent,
    modalWorker,
    confirmDialog: () => import("@/components/confirmDialog")
  },
  data() {
    return {
      valid: false,
      loading: false,
      queue: {
        id: "",
        vip: false,
        remark: "",
        queueDate: "",
        queueTime: "",
        queueBy: "",
        queueById: "",
        queueAt: "",
        workers: []
      },
      datepicker: false,
      status: "",
      snackbar: false,
      agent: false,
      worker: false,
      workerSelected: {}
    };
  },
  watch: {
    queueId(v) {
      if (v == undefined || v == null || v == "") return;

      this.getQueue(v);
    }
  },
  methods: {
    getQueue(id) {
      this.loading = true;
      view(id)
        .then(res => {
          // console.log(res);
          const {
            id,
            vip,
            remark,
            queueDate,
            queueTime,
            queueBy,
            queueById,
            workers,
            queueAt
          } = res;
          this.queue.id = id;
          this.queue.vip = vip;
          this.queue.remark = remark;
          this.queue.queueDate = queueDate;
          this.queue.queueTime = queueTime;
          this.queue.queueBy = queueBy;
          this.queue.workers = workers;
          this.queue.queueAt = queueAt;
          this.queue.queueById = queueById;
        })
        .finally(() => (this.loading = false));
    },
    async update() {
      // console.log("update");
      const d = JSON.stringify(this.queue);
      let dlg = await this.$refs.confirm.open(
        "ยืนยัน",
        `คุณต้องการเปลี่ยนคิว Q-${this.queue.id} ?`,
        { label: "ส่ง SMS", show: true },
        {
          noconfirm: false
        }
      );
      if (!dlg.agree) return;
      if (this.queue.id == "") return;
      updateQueue(this.queue.id, this.queue)
        .then(res => {
          console.log(res);
          if (res.success) {
            this.status = "บันทึกข้อมูลเรียบร้อย";
            this.snackbar = true;
            this.$emit("queueUpdated");
          }
        })
        .catch(err => {
          this.status = err;
          this.snackbar = true;
          this.$emit("queueErrorUpdated");
        });
    },
    async deleteQueue() {
      let dlg = await this.$refs.confirm.open(
        "ยืนยัน",
        `คุณต้องการลบคิว Q-${this.queue.id} ?`,
        { label: "ส่ง SMS", show: false },
        {
          color: "red darken-1",
          noconfirm: false
        }
      );
      if (!dlg.agree) return;
      if (this.queue.id == "") return;
      deleteQ(this.queue.id)
        .then(res => {
          console.log(res);
          if (res.success) {
            this.status = "ลบคิวเรียบร้อย";
            this.snackbar = true;
            this.$emit("queueDeleted");
          }
        })
        .catch(err => {
          this.status = err;
          this.snackbar = true;
          this.$emit("queueErrorUpdated");
        });
    },
    showAgentInfo() {
      if (this.queue.queueById == "") return;
      this.agent = true;
    },
    showWorkerDetail(index) {
      this.workerSelected = Object.assign(
        this.workerSelected,
        this.queue.workers[index]
      );
      this.worker = true;
    },
    async setStatus(id) {
      let res = await setcompleted(id);

      if (res.status) {
        this.status = "บันทึกว่าดำเนินการเสร็จสิ้นเรียบร้อยแล้ว";
        this.snackbar = true;
      }
    }
  }
};
</script>

<style></style>
