<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">รายละเอียด</span>
      </v-card-title>
      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                เลขประจำตัว
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ cid }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                ชื่อ-นามสกุล
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                เบอร์โทร
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ phone }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                ที่อยู่
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ address }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { info } from "../../api/agent";
export default {
  props: ["id", "value"],
  data() {
    return {
      name: "",
      cid: "",
      address: "",
      phone: "",
      email: ""
    };
  },
  watch: {
    id(v) {
      if (v == undefined || v == null || v == "") return;
      this.getInfo(v);
    }
  },
  methods: {
    getInfo(id) {
      info(id).then(res => {
        const { name, cid, address, phone, email } = res;
        this.name = name;
        this.cid = cid;
        this.address = address;
        this.phone = phone;
        this.email = email;
      });
    }
  }
};
</script>

<style></style>
