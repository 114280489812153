<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <search-form @finding="findData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <search-result
          :results="results"
          :total="total"
          :loading="loading"
          :date="date"
          @row-click="expandDetailPanel"
          @hide-detail-click="showDetail = false"
          :show-detail-btn="showDetail"
        />
      </v-col>
      <v-col v-show="showDetail">
        <queue-detail
          :queueId="queueIdSelected"
          @queueUpdated="findData"
          @queueDeleted="findData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import searchForm from "./panels/searchForm";
import searchResult from "./panels/searchResult";
import queueDetail from "../queueManangement/components/eventDetail";
import { queuelist } from "../api/search";

import "moment/locale/th";
export default {
  components: { searchForm, searchResult, queueDetail },
  data() {
    return {
      loading: false,
      results: [],
      total: 0,
      date: "",
      queueIdSelected: "",
      showDetail: false
    };
  },
  created() {
    this.findData({});
  },
  methods: {
    findData(data) {
      this.loading = true;

      if (data && Object.keys(data).length === 0 && data.constructor === Object)
        data = { queueDate: new Date().toISOString().substr(0, 10) };
      this.$moment().locale("th");
      this.date = this.$moment(data.queueDate).format("D MMMM Y");
      queuelist(data)
        .then((res) => {
          this.results = res.results;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    expandDetailPanel(id) {
      this.queueIdSelected = id;
      this.showDetail = true;
    }
  }
};
</script>

<style>
</style>