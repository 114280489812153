import req from "../utils/req";

// get queue by id
export function view(id) {
  return req({
    url: `/queue/${id}`,
    method: "get"
  });
}

// get all queues
export function calEvents(start, end) {
  return req({
    url: `/queue`,
    method: "get",
    params: {
      start: start,
      end: end
    }
  });
}

// for move queue on fc
export function moveQueue(id, data) {
  return req({
    url: `/queue/${id}/move`,
    method: "patch",
    data: data
  });
}

// for update queue on fc
export function updateQueue(id, data) {
  return req({
    url: `/queue/${id}/update`,
    method: "patch",
    data: data
  });
}

// for set queue completes
export function setcompleted(id, data) {
  return req({
    url: `/queue/${id}/completed`,
    method: "patch",
    data: data
  });
}

export function deleteQ(id) {
  return req({
    url: `/queue/${id}`,
    method: "delete"
  });
}
