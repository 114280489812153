<template>
  <v-card elevation="5" class="pa-2">
    <!-- <v-app-bar color="indigo darken-2" dense dark>
      <v-toolbar-title>กรอกข้อมูลเพื่อค้นหา</v-toolbar-title>
    </v-app-bar> -->

    <!-- Date Filter -->
    <v-row>
      <v-col sm="6" md="6" lg="2" cols="12">
        <v-menu
          v-model="datepicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="คิววันที่"
              v-model="queueDate"
              v-bind="attrs"
              v-on="on"
              clearable
              @keypress.enter.stop="findData"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="queueDate"
            @input="datepicker = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col align-self="center" sm="6" md="6" lg="1" cols="12">
        <v-btn color="indigo darken-3" @click="findData" dark>
          <v-icon>mdi-magnify</v-icon>
          แสดง
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      queueDate: new Date().toISOString().substr(0, 10),
      loading: false,
      datepicker: false
    };
  },
  methods: {
    findData() {
      if (this.queueDate === null) return;
      this.$emit("finding", { queueDate: this.queueDate });
    }
  }
};
</script>

<style>
</style>