<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">รายละเอียด</span>
      </v-card-title>
      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                เลขอ้างอิง
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ worker.refId }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                ชื่อ-นามสกุล
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ worker.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                สัญชาติ
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ worker.national }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="$emit('close')">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["id", "value", "worker"],
  data() {
    return {
      name: "",
      cid: "",
      address: "",
      phone: "",
      email: ""
    };
  }
};
</script>

<style></style>
