<template>
  <v-row>
    <v-col cols="12">
      <v-card :loading="loading">
        <v-app-bar color="white" dense flat>
          <v-toolbar-title
            >รายการคิววันที่ {{ date }}(จำนวน {{ totalQ }} คิว)</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn color="indigo darken-3" dark @click="print">
            <v-icon>mdi-printer</v-icon>
            พิมพ์รายการ
          </v-btn>
          <v-btn @click="hidedetail" v-show="showDetailBtn">
            hide detail
            <v-icon> mdi-arrow-expand-right </v-icon>
          </v-btn>
        </v-app-bar>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">QUEUE NUMBER</th>
                <th class="text-left">ช่วงเวลา</th>
                <th class="text-left">จำนวนคิว</th>
                <th class="text-left">จองโดย</th>
                <th class="text-left">เบอร์โทร</th>
                <th class="text-left">หมายเหตุ</th>
                <th class="text-left">ดำเนินการแล้ว</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in results"
                :key="item.id"
                :class="setRowColor(item.status)"
                @click="rowClick(item.id)"
              >
                <td>{{ item.id }}</td>
                <td>{{ timeText(item.isoDate) }}</td>
                <td>{{ item.total }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.relation }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        small
                        icon
                        @click.prevent.stop="setStatus(item.id)"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon>mdi-check-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>เปลี่ยนสถานะเป็นดำเนินการแล้ว</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
    <!-- detail -->

    <!-- to print -->
    <v-col cols="12">
      <div id="printMe" class="d-none">
        <div style="text-align: center; font-weight: bolder; font-size: 20pt">
          <img :src="logo" alt="immlogo" width="30" />
          <span>ตรวจคนเข้าเมืองจังหวัดพระนครศรีอยุธยา</span>
        </div>
        <div style="text-align: center; font-weight: bolder; font-size: 16pt">
          รายการคิววันที่ {{ date }} (จำนวน {{ totalQ }} คิว)
        </div>
        <hr style="text-align: center; font-weight: bolder" />
        <table
          width="100%"
          style="border: solid 1px black; border-collapse: collapse"
        >
          <thead style="border: solid 1px black; border-collapse: collapse">
            <tr>
              <th
                width="15%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                QUEUE NUMBER
              </th>
              <th
                width="5%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                จำนวนคิว
              </th>
              <th
                width="10%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                ช่วงเวลา
              </th>
              <th
                width="30%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                จองโดย
              </th>
              <th
                width="15%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                เบอร์โทร
              </th>
              <th
                width="20%"
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                หมายเหตุ
              </th>
              <th
                class="text-left"
                style="border: solid 1px black; border-collapse: collapse"
              >
                บันทึก
              </th>
            </tr>
          </thead>
          <tbody style="border: solid 1px black; border-collapse: collapse">
            <tr
              v-for="item in results"
              :key="item.id"
              style="border: solid 1px black; border-collapse: collapse"
            >
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ item.id }}
              </td>
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ item.total }}
              </td>
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ timeText(item.isoDate) }}
              </td>
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ item.name }}
              </td>
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ item.phone }}
              </td>
              <td style="border: solid 1px black; border-collapse: collapse">
                {{ item.relation }}
              </td>
              <td
                style="border: solid 1px black; border-collapse: collapse"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import logo from "@/assets/immlogo.png";
import { setcompleted } from "../../api/queue";
export default {
  props: ["results", "total", "loading", "date", "showDetailBtn"],

  data() {
    return {
      showDetail: false,
      queueIdSelected: "",
      logo: logo
    };
  },
  watch: {
    loading() {
      this.showDetail = false;
    }
  },

  computed: {
    totalQ() {
      return this.results.reduce((tt, val) => {
        return tt + val.total;
      }, 0);
      // array.reduce(function(total, currentValue, currentIndex, arr), initialValue)
    }
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
    timeText(date) {
      return this.$moment(date).format("H") == "9"
        ? "09:00-12.00 น."
        : this.$moment(date).format("H") == "13"
        ? "13.00-16.00 น."
        : "16.00-18.00";
    },
    rowClick(id) {
      this.$emit("row-click", id);
    },
    hidedetail() {
      this.$emit("hide-detail-click");
    },
    async setStatus(id) {
      const res = await setcompleted(id);
      if (res.success) {
        // this.$emit('update:results', )
        let index = this.results.findIndex(item => item.id == id);
        let result = this.results[index];
        // console.log(index);
        this.results[index].status = result.status == 0 ? 1 : 0;
      }
    },
    setRowColor(status) {
      return status == 1 ? "success lighten-3" : "tranparent";
    }
  }
};
</script>

<style>
.c {
  font-weight: bolder;
  font-size: 20pt;
}
</style>
